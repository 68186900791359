/* styles */
.hero-image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  max-width: 40vw;
}
@media (width: 768px) {
  .hero-image {
    margin: 2rem 0 0 5rem;
  }
}
@media (max-width: 768px) {
  .hero-image {
    max-width: 70vw;
  }
}
/* .centered-wrapper {
    display: flex;
    justify-content: center;
} */

.faq-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.faq-link {
  color: #ff147d;
  font-size: 14px;
  /* text-align: left; */
}
.image2 {
  min-height: 20vh;
  height: 100%;
}
.business-gro-phone {
  height: 100%;
  min-height: auto;
  object-fit: cover;
  border-radius: 5%;
}
.business-gro-box1 {
  color: #ffffff;
  border-radius: 5%;
  padding: 50px;
  /* min-height: 500px;
    height: 100%; */
  /* margin: 2rem; */
}
.cards-container {
  margin-top: 100px;
}
@media (min-width: 769px) {
  .business-gro-box1 {
    padding: 100px 50px;
  }
}
.card {
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 100px 30px;
  line-height: 2px;
  height: 100%;
  border-radius: 10px;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
}
.cardheading {
  margin-bottom: 100px;
  font-size: 30px;
  font-weight: 600;
}
.carddesc {
  font-weight: 700;
  font-size: 70px;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
}
.partners {
  width: 50%;
  margin: auto;
}

.contain {
  padding: 20px;
}
@media (min-width: 769px) {
  .contain {
    padding-inline: 80px;
  }
  .contain img {
    margin: auto;
  }
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gooper, serif;
  letter-spacing: -0.015em;
  font-weight: 700;
  line-height: 1.25;
}
h1 {
  font-size: 3rem;
  line-height: 1;
}
@media (min-width: 768px) {
  h1 {
    font-size: 5.25rem;
  }
}
h2 {
  font-size: 2.25rem;
}
@media (min-width: 768px) {
  h2 {
    font-size: 4rem;
  }
}
h3 {
  font-size: 1.875rem;
}
@media (min-width: 768px) {
  h3 {
    font-size: 2.25rem;
  }
}
h4 {
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  h4 {
    font-size: 1.875rem;
  }
}
h5 {
  font-size: 1.5rem;
}
h5,
h6 {
  letter-spacing: -0.025em;
  line-height: 1.5;
}
h6 {
  font-size: 1.25rem;
}
a,
button,
div,
li,
p {
  font-weight: 500;
}

.button,
button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 0.3s;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 9999px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: 0 0;
  color: #fff;
}
.button span,
button span {
  position: relative;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.button:after,
.button:before,
button:after,
button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
}
.button:before,
button:before {
  background: #000;
}
.button:after,
.button:before,
button:after,
button:before {
  content: '';
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.7, 1);
}
.button:after,
button:after {
  border: 1px solid #000;
  transform: scale3d(0.85, 0.65, 1);
}
.button:hover:before,
button:hover:before {
  transform: scale3d(0.9, 0.8, 1);
}
.button:hover:after,
button:hover:after {
  transform: scaleX(1);
}
.button.button--pink:before,
button.button--pink:before {
  background: #ff147d;
}
.button.button--pink:after,
button.button--pink:after {
  border: 1px solid #ff147d;
}
.button.button--pink-light span {
  color: #000000;
}
.button.button--pink-light:before,
button.button--pink-light:before {
  background: #ffe8f2;
}
.button.button--pink-light:after,
button.button--pink-light:after {
  border: 1px solid #ffe8f2;
}
.button.button--white:before,
button.button--white:before {
  background: #fff;
}
.button.button--white:after,
button.button--white:after {
  border: 1px solid #fff;
}
.button.button--white span,
button.button--white span {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}
.button.button--grey:before,
button.button--grey:before {
  background: #f1f1f1;
}
.button.button--grey:after,
button.button--grey:after {
  border: none;
}
.button.button--grey:hover:before,
button.button--grey:hover:before {
  transform: none;
}
.button.button--grey span,
button.button--grey span {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}
.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-margin {
  padding-top: 0;
  padding-bottom: 0;
}
.visible-small {
  display: none;
}
.hidden-small {
  display: block;
}

/* component styles */
nav.site-header {
  position: fixed;
  margin-top: 1rem;
  width: 100%;
  z-index: 50;
  height: 4rem;
  display: flex;
  top: 0;
  align-items: center;
  transition-property: all;
  transition-duration: 0.15s;
  background-color: transparent;
}
@media (min-width: 768px) {
  nav.site-header {
    height: 5rem;
  }
}
nav.site-header.sticky {
  margin-top: 0;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  /* box-shadow:0 2px 1px rgba(0,0,0,.05) */
}
nav.site-header .logo {
  width: 16.666667%;
}
nav.site-header .logo a {
  width: 132px;
  display: inline-block;
  height: 30px;
  margin-top: 11px;
}
nav.site-header .logo a svg {
  transition: opacity 0.4s ease;
}
nav.site-header .logo a .logo-white {
  opacity: 0;
}
nav.site-header .nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}
nav.site-header .logo {
  width: 132px;
  float: left;
}
nav.site-header .hiring-cta {
  float: left;
  margin-left: 20px;
}
.nav-menu__list {
  float: right;
}
@media (min-width: 768px) {
  nav.site-header .nav-menu {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
nav.site-header .nav-menu__list,
nav.site-header ul.menu-items {
  justify-content: space-between;
  align-items: center;
}
nav.site-header ul.menu-items {
  display: flex;
}
nav.site-header ul.menu-items li {
  margin-right: 20px;
  font-size: 1.125rem;
}
nav.site-header ul.menu-items li svg {
  fill: #000;
}
nav.site-header ul.menu-items li a,
nav.site-header ul.menu-items li span {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: relative;
}
nav.site-header ul.menu-items li a:hover,
nav.site-header ul.menu-items li span:hover {
  --text-opacity: 1;
  color: #ff147d;
  color: rgba(255, 20, 125, var(--text-opacity));
}
nav.site-header ul.menu-items li a:hover svg,
nav.site-header ul.menu-items li span:hover svg {
  fill: #ff147d;
}
nav.site-header ul.menu-items li ul.menu-dropdown,
.menu-products-dropdown.active,
.menu-learn-dropdown.active {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  --text-opacity: 1;
  color: #828282;
  color: rgba(130, 130, 130, var(--text-opacity));
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  z-index: 50;
  padding: 1.5rem 1.25rem;
  top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transition: all 0.2s ease;
  transform: translateZ(0);
}
nav.site-header ul.menu-items li ul.menu-dropdown li,
.menu-products-dropdown.active li,
.menu-learn-dropdown.active li {
  margin-right: 0;
  margin-bottom: 1rem;
}
nav.site-header ul.menu-items li ul.menu-dropdown li:last-child,
.menu-products-dropdown.active li:last-child,
.menu-learn-dropdown.active li:last-child {
  margin-bottom: 0;
}
nav.site-header ul.menu-items li ul.menu-dropdown.active,
.menu-products-dropdown.active,
.menu-learn-dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 52px, 0);
}
nav.site-header ul.menu-items li svg {
  display: inline-block;
  margin-left: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  height: 0.9em;
  width: 0.9em;
}
nav.site-header .cta-area {
  align-items: center;
}
nav.site-header .cta-area .button {
  margin-left: 0.5rem;
}
nav .mobile-menu {
  display: block;
}
@media (min-width: 768px) {
  nav .mobile-menu {
    display: none;
  }
}
nav .mobile-menu__trigger {
  position: relative;
  height: 2rem;
  width: 2rem;
  background: transparent;
  padding: 0;
  z-index: 1;
}
nav .mobile-menu__trigger span {
  height: 2rem;
  width: 2rem;
  display: inline-block;
}
nav .mobile-menu__trigger:after,
nav .mobile-menu__trigger:before {
  display: none;
}
nav .mobile-menu__trigger span:after,
nav .mobile-menu__trigger span:before,
.dropdown-content.show span:after,
.dropdown-content.show span:before {
  content: '';
  position: absolute;
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
  height: 2px;
  width: 24px;
  transition: transform 0.3s ease;
}
nav .mobile-menu__trigger span:before {
  top: 8px;
  left: 2px;
}
nav .mobile-menu__trigger span:after {
  top: 18px;
  left: 2px;
}
nav .mobile-menu__trigger.active span:before,
.dropdown-content.show span:before {
  transform: translate3d(-1.7px, -5px, 0) rotate(45deg);
}
nav .mobile-menu__trigger.active span:after,
.dropdown-content.show span:after {
  transform: translate3d(-2px, -6px, 0) rotate(-45deg);
}
@media (min-width: 768px) {
  nav .mobile-menu__wrapper {
    display: none;
  }
}
nav .mobile-menu__wrapper.active {
  display: flex;
  flex-direction: column;
}
nav .mobile-menu__wrapper ul li {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
nav .mobile-menu__wrapper ul li a {
  font-size: 1.875rem;
  font-family: Gooper, Cambria, 'Times New Roman', Times, serif;
  width: 100%;
}
nav .mobile-menu__wrapper ul li a,
nav .mobile-menu__wrapper ul li span {
  font-weight: 700;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
nav .mobile-menu__wrapper ul li span {
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  opacity: 0.75;
}
nav .mobile-menu__wrapper ul li ul li {
  border-bottom: none;
}
footer.site-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  --text-opacity: 1;
  color: #828282;
  color: rgba(130, 130, 130, var(--text-opacity));
}
footer.site-footer h6 {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  margin-bottom: 1.5rem;
}
footer.site-footer .branding-section svg {
  width: 8rem;
  height: 2rem;
  margin-bottom: 1.5rem;
  display: inline-block;
}
footer.site-footer .footer-menu {
  margin-bottom: 32px;
}
footer.site-footer .footer-menu li,
footer.site-footer .footer-menu p {
  font-size: 1rem;
  color: #676767;
  margin-bottom: 1rem;
}
footer.site-footer .footer-menu li a,
footer.site-footer .footer-menu p a {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.3s;
}
footer.site-footer .footer-menu li a:hover,
footer.site-footer .footer-menu p a:hover {
  --text-opacity: 1;
  color: #ff147d;
  color: rgba(255, 20, 125, var(--text-opacity));
}
footer.site-footer .footer-menu h6 {
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
footer.site-footer .footer-menu .footer-social__icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
footer.site-footer .footer-menu .footer-social__icons a:hover svg {
  fill: #ff147d;
}
footer.site-footer .footer-menu .footer-social__icons a svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  fill: #000;
  transition: all 0.3s ease;
}
footer.site-footer .copyright-text {
  font-size: 14px;
  color: #676767;
  display: block;
}
.footer-cta,
.license-cta {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.footer-cta__card,
.license-cta__card {
  --bg-opacity: 1;
  border-radius: 1.5rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 300px;
}
.footer-cta__card {
  background-color: #ffe8f2;
}
.license-cta__card {
  background-color: #d0ddf8;
  background-size: cover;
  background-image: url('../../assets/licensed-bg.png');
  background-position: center;
}

.footer-cta__card p {
  font-weight: 500;
  font-size: 1.125rem;
}
.footer-cta__umbrella {
  position: absolute;
  right: 0;
  bottom: -20px;
  height: 280px;
  width: 280px;
}
section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
section.grey-hero {
  --bg-opacity: 1;
  background-color: #f2f2f7;
  background-color: rgba(242, 242, 247, var(--bg-opacity));
  margin-top: -40px;
  padding-top: 40%;
  padding-bottom: 20%;
}
section.hero-section {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  min-height: 660px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 84% 60px;
}
.hero-video {
  border-radius: 20px;
  border: 5px solid #000000;
}
@media (min-width: 1024px) {
  section {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 768px) {
  section.grey-hero {
    padding-top: 15%;
    padding-bottom: 10%;
  }
}
@media (min-width: 768px) {
  section.hero-section {
    background-size: 100% auto;
    background-position: center;
    /* min-height:800px; */
    height: 700px;
  }
}
section.hero-section.landing-page {
  background-image: url('../../assets/landing-hero-wide.jpg');
}
section.hero-section.health-hero {
  background-image: url('../../assets/health-hero-wide.jpg');
}
section.hero-section.income-protection-hero {
  background-image: url('../../assets/income-protection-hero-wide.jpg');
}
section.hero-section.claims-hero {
  background-image: url('../../assets/claims-hero-wide.jpg');
}
section.hero-section.health-cash-hero {
  background-image: url('../../assets/landing-health-cash.jpg');
}
section.hero-section.partners-page {
  background-image: url('../../assets/partners-hero-wide.jpg');
}
section.hero-section.business-gro-page {
  background-image: url('../../assets/businessgro.jpg');
}
section.hero-section.business-page {
  background-image: url('../../assets/business-hero-wide.jpg');
}
section.hero-section.credit-life-page {
  background-image: url('../../assets/credit-life-hero-wide.jpg');
}
section.hero-section.about-page {
  background-image: url('../../assets/about-hero-wide.jpg');
}
section.hero-section.event-board {
  background-image: url('../../assets/landing-hero-wide.jpg');
}
.leaf-petals--background {
  background-image: url('../../assets/leaf-petals-bg.png');
  background-size: cover;
}
.video-container {
  background-image: url('../../assets/about-video.mp4');
  background-size: cover;
}
@media (min-width: 768px) {
  section.hero-section {
    margin: 6rem 3rem 2rem;
    padding: 6rem 1rem;
  }
  section.hero-sectionbiz {
    margin: 2rem 3rem 2rem;
    padding: 3rem 1rem;
  }
}
section.hero-section h1 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 3rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  line-height: 1.1;
}

section.hero-section.event-board h1 {
  font-size: 4rem;
}
@media (max-width: 500px) {
  section.hero-section.event-board {
    background-size: cover;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  section.hero-section.event-board h1 {
    margin-top: 0;
    font-size: 2rem;
  }
}
section.hero-sectionbiz h1 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 3rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  line-height: 1.1;
}
@media (min-width: 1024px) {
  section.hero-section h1 {
    margin-top: 54px;
    margin-bottom: 27px;
    font-size: 82px;
  }
  section.hero-sectionbiz h1 {
    margin-top: 54px;
    margin-bottom: 27px;
    font-size: 82px;
  }
}
section.hero-section p {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
section.hero-sectionbiz p {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
section .number-circle {
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  flex-shrink: 0;
  text-align: center;
  font-weight: 600;
  font-family: Gooper, Cambria, 'Times New Roman', Times, serif;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  font-size: 1.5rem;
  border: 3px solid #000;
  border-radius: 100%;
  line-height: 1.8;
  height: 2em;
  width: 2em;
}
@media (min-width: 768px) {
  section .number-circle {
    font-size: 1.875rem;
    height: 2em;
    width: 2em;
  }
}
section .pill-item {
  position: absolute;
  padding: 0.25rem 0.75rem;
  text-align: center;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: 1.125rem;
}
section.faq-section {
  margin-bottom: 6rem;
}
section.faq-section .faq-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  margin-top: 4rem;
  align-items: start;
}
@media (min-width: 1024px) {
  section.faq-section .faq-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
  }
}
.photo {
  overflow: hidden;
  opacity: 0;
  border: 2px solid #000;
  transition: opacity 0.1s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.photo.photo--half-rounded {
  height: 380px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
@media (min-width: 1280px) {
  .photo.photo--half-rounded {
    height: 600px;
    width: 95%;
  }
}
.photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform-origin: center;
}
.accordion,
.faq-row {
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  padding-right: 2rem !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.75rem;
  cursor: pointer;
  border-radius: 1rem;
  border: 2px solid #000;
  transition: all 0.4s ease;
  min-height: 60px !important;
  padding-left: 72px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.faq-row-wrapper {
  margin-top: 50px;
  background: transparent !important;
  width: 50%;
  display: inline-flex;
  padding: 10px;
}
.faq-row {
  padding-left: 22px !important;
  margin-bottom: 20px;
  border: 2px solid #000 !important;
}
.faq-row .row-content-text {
  font-size: 16px;
  margin-bottom: 15px;
}
.accordion:hover {
  border-color: #ff147d;
}
.accordion h6 {
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25;
  pointer-events: none;
}
.faq-row .row-title-text {
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  padding-right: 0 !important;
}
@media (min-width: 1024px) {
  .accordion h6 {
    font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    font-size: 1.5rem;
  }
}
.accordion p {
  font-size: 1.125rem;
  visibility: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  pointer-events: none;
  opacity: 0.7;
}
.accordion p a {
  --text-opacity: 1;
  color: #ff147d;
  color: rgba(255, 20, 125, var(--text-opacity));
}
.accordion:before {
  content: '';
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  left: 20px;
  background-size: cover;
  background-image: url('../../assets/accordion-plus.b3bb226.svg');
}
@media (min-width: 1024px) {
  .accordion:before,
  .faq-row:before {
    left: 24px;
    height: 32px;
    width: 32px;
  }
}
.accordion.open p {
  visibility: visible;
  display: block;
}
.accordion.open:before,
.faq-row.expanded:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEyNzBfNjM0KSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgzMnYzMkgweiIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAxNS45OUMwIDcuMTU4IDcuMTU4IDAgMTUuOTkgMHMxNS45OSA3LjE1OCAxNS45OSAxNS45OS03LjE1OCAxNS45OS0xNS45OSAxNS45OVMwIDI0LjgyMiAwIDE1Ljk5ek0xNS45OSAxLjYzYy03LjkwNSAwLTE0LjM2IDYuNDU1LTE0LjM2IDE0LjM2IDAgNy45MDUgNi40NTUgMTQuMzYgMTQuMzYgMTQuMzYgNy45MDUgMCAxNC4zNi02LjQ1NSAxNC4zNi0xNC4zNiAwLTcuOTA1LTYuNDU1LTE0LjM2LTE0LjM2LTE0LjM2eiIgZmlsbD0iIzAwMCIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEgMTZhMSAxIDAgMCAxIDEtMWg5YTEgMSAwIDEgMSAwIDJoLTlhMSAxIDAgMCAxLTEtMXoiIGZpbGw9IiMwMDAiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMF8xMjcwXzYzNCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgzMnYzMkgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
}
.immersive-section {
  position: relative;
  overflow: hidden;
  min-height: 500px;
}
@media (min-width: 1280px) {
  .immersive-section {
    min-height: 800px;
  }
}
.immersive-section .container {
  height: 400px;
}
@media (min-width: 768px) {
  .immersive-section .container {
    min-height: 550px;
  }
}
.immersive-section h1 {
  font-size: 3rem;
}
@media (min-width: 768px) {
  .immersive-section h1 {
    font-size: 5.25rem;
  }
}
.immersive-section .immersive-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 100%;
  z-index: -1;
}
@media (min-width: 1280px) {
  .immersive-section .immersive-image {
    height: 900px;
  }
}
.immersive-section .immersive-image img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.value-props-section .our-products {
  width: 100%;
  display: block;
}
.value-props-section .card-item {
  position: relative;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 4rem;
  min-height: 500px;
  width: 100%;
  overflow: hidden;
}
.value-props-section .card-item p {
  font-size: 18px;
}
@media (min-width: 1280px) {
  .value-props-section .card-item {
    width: 31%;
    display: inline-flex;
    margin: 1%;
  }
}
.value-props-section .card-item a {
  border-radius: 40px;
  padding: 0.75rem 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.value-props-section .card-item:first-child {
  background: #fff8db;
}
.value-props-section .card-item:first-child a {
  background-color: #ece3c1;
  color: #000000;
}
.value-props-section .card-item:nth-child(2) {
  background: #e7ffec;
}
.value-props-section .card-item:nth-child(2) a {
  background-color: #c0ffc7;
  color: #000000;
}
.value-props-section .card-item:nth-child(3) {
  background: #ebf4ff;
}
.value-props-section .card-item:nth-child(3) a {
  background-color: #cbecff;
  color: #000000;
}

.value-props-section .card-item:first-child a svg,
.value-props-section .card-item:nth-child(2) a svg {
  display: inline-block;
  position: relative;
  top: -2px;
  left: 2px;
}
.value-props-section .card-item img {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px 10px 0 0;
}
.howto-section {
  --bg-opacity: 1;
  background-color: #fff1f8;
  background-color: rgba(255, 241, 248, var(--bg-opacity));
}
.howto-section h1 {
  line-height: 1.1;
}
.howto-section img {
  width: 100%;
  max-width: 500px;
  border-radius: 25px;
  border: 6px solid #000000;
}
.howto-section .steps-area {
  display: flex;
  flex-direction: column;
}
.howto-section .steps-area .step-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.howto-section .steps-area .step-item p {
  font-size: 1.25rem;
  margin-left: 0.5rem;
}
@media (min-width: 1024px) {
  .howto-section .steps-area .step-item p {
    font-size: 1.5rem;
  }
}
.claims-demo-section .demo-card {
  width: 100%;
  position: relative;
  border-radius: 0.75rem;
  overflow: visible;
  height: 500px;
}
.claims-demo-section img {
  width: 100%;
  max-width: 500px;
  border-radius: 25px;
}
@media (min-width: 1024px) {
  .claims-demo-section .demo-card {
    width: 75%;
  }
}
.claims-demo-section .demo-card .play-button {
  position: absolute;
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: 64px;
  width: 64px;
  top: 40%;
  left: 43%;
}
.claims-demo-section .demo-card .pill-item {
  top: -10px;
  left: -30px;
  transform: rotate(-18deg);
  background: #fcdd72;
}
.testimonials-section .testimonial-card__text {
  padding: 2rem 2.5rem;
  width: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #fff1f8;
  border: 3px solid #000;
  height: auto;
  min-height: 280px;
}
@media (min-width: 1024px) {
  .testimonials-section .testimonial-card__text {
    height: 500px;
  }
}
.testimonials-section .testimonial-photos {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
@media (min-width: 1024px) {
  .testimonials-section .testimonial-photos {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
  }
}
.testimonials-section .testimonial-photos .testimonial__photo {
  border-radius: 1rem;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: filter 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  vertical-align: top;
}
@media (min-width: 1024px) {
  .testimonials-section .testimonial-photos .testimonial__photo {
    height: 235px;
  }
}
.testimonials-section .testimonial-photos .testimonial__photo:hover img {
  filter: none;
}
.testimonials-section .testimonial-photos .active,
.testimonials-section .testimonial-photos .testimonial__photo.active {
  border: 3px solid #000;
}
.testimonials-section .testimonial-photos .testimonial__photo.active img {
  filter: none;
}
.testimonials-section .testimonial-photos .testimonial__photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  filter: grayscale(100%);
  vertical-align: top;
}
.testimonials-section .testimonial-photos .active {
  height: 235px;
  border-radius: 1rem;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: filter 0.3s ease-in-out;
  backface-visibility: hidden;
  transform: translateZ(0);
  vertical-align: top;
}
.testimonials-section .testimonial-photos .active img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  filter: none;
}
section .pill-item {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  section .pill-item {
    display: block;
  }
}
.health-steps-section .step-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.75rem 1.5rem 1rem;
  margin-bottom: 3rem;
  width: 100%;
  border-radius: 1rem;
  border: 2px solid #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (min-width: 1280px) {
  .health-steps-section .step-item {
    align-self: flex-end;
    width: 85%;
  }
}
.health-steps-section .step-item h5 {
  font-weight: 700;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
}
.health-steps-section .step-item p {
  font-size: 1.25rem;
  opacity: 0.75;
}
.health-steps-section .step-item svg {
  width: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
  flex-shrink: 0;
}
.brands-section p {
  opacity: 0.5;
  margin-top: 50px;
}
.hospitals-section,
.brands-section {
  overflow: hidden;
  padding-top: 0;
}
.hospitals-section .hospitals-carousel-container,
.brands-section .hospitals-carousel-container {
  position: relative;
  overflow: hidden;
}
.hospitals-section .hospitals-carousel-container:before,
.brands-section .hospitals-carousel-container:before {
  content: '';
  height: 120px;
  width: 200px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
  display: none;
}
.hospitals-section .hospitals-carousel-container .hospitals-carousel__wrapper,
.brands-section .hospitals-carousel-container .hospitals-carousel__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 2rem;
  width: 400%;
  transition: all 0.4s ease;
  -webkit-animation: carousel-animation-1 33s linear infinite;
  animation: carousel-animation-1 33s linear infinite;
}
@media (min-width: 1280px) {
  .hospitals-section .hospitals-carousel-container .hospitals-carousel__wrapper,
  .brands-section .hospitals-carousel-container .hospitals-carousel__wrapper {
    width: 160%;
    margin-top: 0;
  }
}

.hospitals-section
  .hospitals-carousel-container
  .hospitals-carousel__wrapper
  .carousel-item,
.brands-section
  .hospitals-carousel-container
  .hospitals-carousel__wrapper
  .carousel-item {
  margin-right: 2rem;
  font-family: Gooper, Cambria, 'Times New Roman', Times, serif;
  font-size: 1.5rem;
  opacity: 0.5;
}
.hospitals-carousel-container
  .hospitals-carousel__wrapper.brand_logos
  .carousel-item {
  margin-left: 100px;
  margin-right: 100px;
}
@-webkit-keyframes carousel-animation-1 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@keyframes carousel-animation-1 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.health-perks-section svg {
  height: 92px;
  width: 92px;
}
.health-perks-section h5 {
  font-weight: 600;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.health-perks-section p {
  opacity: 0.75;
}
.health-perks-section .coverage-features.coverage-features-two {
  text-align: center;
}
.health-perks-section .coverage-features .perk-item {
  max-width: 400px;
  margin: 60px 0;
  padding: 0 40px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.credit-life-section.health-perks-section .coverage-features .perk-item {
  border: 1px solid #ededed;
  width: calc(33% - 30px);
  margin: 15px;
  border-radius: 4px;
  padding: 40px 15px;
  min-height: 400px;
}
.credit-life-section.health-perks-section .coverage-features .perk-item p {
  opacity: 1;
}
.health-perks-section .coverage-features .perk-item img {
  margin: auto;
}
.health-perks-section .coverage-features .perk-item p {
  font-size: 18px;
}
.health-cash-section .pill-item {
  background-color: #e6eceb;
}
.health-relief-section {
  --bg-opacity: 1;
  background-color: #ff4397;
  background-color: rgba(255, 67, 151, var(--bg-opacity));
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.health-relief-section h2,
.health-relief-section p {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.health-relief-section h2 {
  line-height: 1.1;
}
.health-relief-section .relief-perk-card {
  border-radius: 1rem;
  padding-top: 1rem;
  position: relative;
  width: 100%;
  margin-left: auto;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border: 2px solid #000;
  height: 400px;
  box-shadow: 8px 8px 0 #000;
}
.health-relief-section .relief-perk-card h5 {
  font-weight: 600;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
}
@media (min-width: 1280px) {
  .health-relief-section .relief-perk-card {
    width: 90%;
  }
}
.health-relief-section .relief-perk-card .relief-perk-card__inner {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-top: 2px solid #000;
}
.health-relief-section .relief-perk-card .relief-perk-card__text {
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
  border-radius: 1rem;
  display: flex;
  border: 2px solid #000;
}
.health-relief-section .relief-perk-card .relief-perk-card__text svg {
  width: 2rem;
  height: 2rem;
  margin-top: 0.25rem;
  flex-shrink: 0;
}
.health-relief-section .relief-perk-card .relief-perk-card__text p {
  font-size: 1.25rem;
  margin-left: 1rem;
  font-weight: 600;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  letter-spacing: -0.025em;
  line-height: 1.25;
}
.health-relief-section .relief-perk-card .sparkle {
  position: absolute;
  width: 72px;
  height: 72px;
  bottom: 0;
  left: -42px;
}
.claims-steps-section .step-item {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.claims-steps-section .step-item .number-circle {
  font-size: 1.5rem;
}
.claims-steps-section .step-item svg {
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  margin-top: -3px;
  margin-left: -3px;
}
.claims-steps-section .step-item p {
  font-size: 1.5rem;
  font-weight: 600;
}
.claims-ai-section,
.what-you-get-section {
  --bg-opacity: 1;
}
.fature-section {
  color: #000000;
  position: relative;
}
.pink--background {
  background: #ff147d;
}
.dark-pink--background {
  background: #660832;
}
.light-pink--background {
  background: #fff1f8;
}
.green--background {
  background: #55f86b;
}
.light-green--background {
  background: #e9ffec;
}
.purple--background {
  background: #825ae2;
}
.black--background {
  background: #000000;
}
.gray--background {
  background: #979797;
}
.light-gray--background {
  background: #f2f2f7;
}
.light-lime--background {
  background: #f7f3ef;
}
.light-grey--background {
  background: #fbfaff;
}
.dark-green--background {
  background: #073d36;
}
.dark-blue--background {
  background: #3774ec;
}
.white--background {
  background: #fff;
  color: #000;
}
.what-you-get-section {
  background: #ff147d;
  color: #ffffff;
  background-image: url('../../assets/divider-health-cash.png');
  background-repeat: no-repeat;
  background-position: center;
}
.image-layouts.scale-img img {
  border-radius: 10px;
}
.claims-ai-section .image-layout,
.what-you-get-section .image-layout {
  height: 400px;
  width: 100%;
}
@media (min-width: 1024px) {
  .claims-ai-section .image-layout,
  .what-you-get-section .image-layout {
    height: 540px;
  }
}
.fature-section .image-layout img,
.claims-ai-section .image-layout img,
.what-you-get-section .image-layout img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.fature-section .image-layout img {
  object-fit: cover;
}
.estimate-section .get-amount {
  position: relative;
}
.estimate-section .get-amount svg {
  position: absolute;
  height: 72px;
  width: 72px;
  top: -50px;
  right: 15%;
}
@media (min-width: 768px) {
  .estimate-section .get-amount svg {
    right: -15%;
  }
}
.estimate-section .salary-input-field {
  position: relative;
}
.estimate-section .salary-input-field input[type='text'],
.estimate-section .salary-input-field input[type='number'] {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding: 1.5rem;
  border-radius: 1rem;
  text-align: right;
  font-family: Gooper, Cambria, 'Times New Roman', Times, serif;
  font-size: 3rem;
  width: 100%;
  border: 1px solid #ff147d;
  min-height: 84px;
}
.payable-premium {
  color: #ff147d;
  display: flex;
  margin-top: 5px;
}
.payable-premium span {
  margin-right: 10px;
}
.estimate-section .salary-input-field input[type='text']:focus,
.estimate-section .salary-input-field input[type='number']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.estimate-section .salary-input-field:before {
  font-size: 1rem;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  content: 'If you earn';
  position: absolute;
  top: 32px;
  left: 32px;
}
.estimate-section .salary-percentiles {
  display: flex;
  align-items: center;
}
.estimate-section .salary-percentiles div {
  margin-right: 0.75rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  --text-opacity: 1;
  color: #ff147d;
  color: rgba(255, 20, 125, var(--text-opacity));
  letter-spacing: 0.05em;
  font-feature-settings: 'tnum';
  transition: all 0.2s ease;
  border: 2px solid #ff147d;
}
.estimate-section .salary-percentiles div.active {
  --bg-opacity: 1;
  background-color: #ff147d;
  background-color: rgba(255, 20, 125, var(--bg-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.estimate-section h1 sub {
  font-size: 1.5rem;
  opacity: 0.5;
  letter-spacing: 0.025em;
  font-family: Fellix, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  bottom: 4px;
}
.income-policy-details-section {
  padding-bottom: 0;
  overflow: visible;
}
.income-policy-details-section .container {
  height: auto;
}
@media (min-width: 1024px) {
  .income-policy-details-section {
    overflow: hidden;
  }
}
.income-policy-details-section h2 {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}
@media (min-width: 1024px) {
  .income-policy-details-section h2 {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }
}
.text-cta-secton,
.income-protection-section {
  background: rgba(233, 255, 236, 0.4);
}
.text-cta-secton img,
.income-protection-section img {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.text-cta-secton a {
  display: none;
}
.visble-cta .text-cta-secton a {
  display: inline-block;
}
.income-policy-details-section .income-policy-card {
  width: 100%;
  padding: 2rem;
  position: relative;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 1rem 1rem 0 0;
  margin-top: 3rem;
  min-height: 450px;
  border: 2px solid #000;
}
@media (min-width: 1024px) {
  .income-policy-details-section .income-policy-card {
    padding: 2.5rem;
    border: 0;
    min-height: 500px;
  }
}
.income-policy-details-section .income-policy-card .faq-row-wrapper {
  width: 100%;
  display: block;
}
.income-policy-details-section .income-policy-card .accordion,
.income-policy-details-section .income-policy-card .faq-row-wrapper .faq-row {
  border-radius: 0;
  padding-left: 3rem;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid #000;
}
.income-policy-details-section .income-policy-card .accordion:before {
  left: 0;
}
.income-policy-details-section .income-policy-card .faq-row-wrapper .faq-row {
  border-radius: 0 !important;
  margin-bottom: 1rem !important;
  border: 0 !important;
  border-bottom: 1px solid #000 !important;
}
.anim-text {
  position: relative;
  overflow: hidden;
  display: block;
}
.anim-text.dark-bg.active:after,
.anim-text.dark-bg.active:before {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}
.anim-text.active:after {
  -webkit-animation: a-ltr-after 2.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  animation: a-ltr-after 2.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  transform: translateX(-101%);
}
.anim-text.active:after,
.anim-text.active:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #fff;
}
.anim-text.active:before {
  -webkit-animation: a-ltr-before 2.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  animation: a-ltr-before 2.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  transform: translateX(0);
}
@-webkit-keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(101%);
  }
}
@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(101%);
  }
}
@-webkit-keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
}
@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
}
@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (min-width: 640px) and (min-width: 600px) {
  .sm\:container {
    max-width: 600px;
  }
}
@media (min-width: 640px) and (min-width: 728px) {
  .sm\:container {
    max-width: 728px;
  }
}
@media (min-width: 640px) and (min-width: 984px) {
  .sm\:container {
    max-width: 984px;
  }
}
@media (min-width: 640px) and (min-width: 1240px) {
  .sm\:container {
    max-width: 1240px;
  }
}
@media (min-width: 640px) and (min-width: 1300px) {
  .sm\:container {
    max-width: 1300px;
  }
}
@media (min-width: 768px) {
  .md\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (min-width: 768px) and (min-width: 600px) {
  .md\:container {
    max-width: 600px;
  }
}
@media (min-width: 768px) and (min-width: 728px) {
  .md\:container {
    max-width: 728px;
  }
}
@media (min-width: 768px) and (min-width: 984px) {
  .md\:container {
    max-width: 984px;
  }
}
@media (min-width: 768px) and (min-width: 1240px) {
  .md\:container {
    max-width: 1240px;
  }
}
@media (min-width: 768px) and (min-width: 1300px) {
  .md\:container {
    max-width: 1300px;
  }
}
@media (min-width: 768px) {
  .md\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fff1f8;
    background-color: rgba(255, 241, 248, var(--bg-opacity));
  }
  .md\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #ffe8f2;
    background-color: rgba(255, 232, 242, var(--bg-opacity));
  }
  .md\:hidden {
    display: none;
  }
  .md\:block {
    display: block;
  }
  .md\:flex {
    display: flex;
  }
  .md\:text-xl {
    font-size: 1.25rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
  }
  .md\:mr-10 {
    margin-right: 2.5rem;
  }
  .md\:mr-24 {
    margin-right: 6rem;
  }
  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-1\/4 {
    width: 25%;
  }
  .md\:w-2\/4 {
    width: 50%;
  }
  .md\:w-3\/4 {
    width: 75%;
  }
  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  .md\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (min-width: 1024px) and (min-width: 600px) {
  .lg\:container {
    max-width: 600px;
  }
}
@media (min-width: 1024px) and (min-width: 728px) {
  .lg\:container {
    max-width: 728px;
  }
}
@media (min-width: 1024px) and (min-width: 984px) {
  .lg\:container {
    max-width: 984px;
  }
}
@media (min-width: 1024px) and (min-width: 1240px) {
  .lg\:container {
    max-width: 1240px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .lg\:container {
    max-width: 1300px;
  }
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:order-first {
    order: -9999;
  }
  .lg\:order-last {
    order: 9999;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:mt-12 {
    margin-top: 3rem;
  }
  .lg\:mr-12 {
    margin-right: 3rem;
  }
  .lg\:mt-32 {
    margin-top: 8rem;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
  .lg\:w-3\/5 {
    width: 60%;
  }
  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }
  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (min-width: 1280px) and (min-width: 600px) {
  .xl\:container {
    max-width: 600px;
  }
}
@media (min-width: 1280px) and (min-width: 728px) {
  .xl\:container {
    max-width: 728px;
  }
}
@media (min-width: 1280px) and (min-width: 984px) {
  .xl\:container {
    max-width: 984px;
  }
}
@media (min-width: 1280px) and (min-width: 1240px) {
  .xl\:container {
    max-width: 1240px;
  }
}
@media (min-width: 1280px) and (min-width: 1300px) {
  .xl\:container {
    max-width: 1300px;
  }
}
@media (min-width: 1280px) {
  .xl\:block {
    display: block;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.nuxt-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 0;
  opacity: 1;
  transition: width 0.1s, opacity 0.4s;
  background-color: #000;
  z-index: 999999;
}
.nuxt-progress.nuxt-progress-notransition {
  transition: none;
}
.nuxt-progress-failed {
  background-color: red;
}

.faq-body {
  padding: 0;
}
.light-green-alt-400 {
  background: #e9ffec;
}
.claims-demo-section {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.testimonials-section .grid {
  gap: 2rem;
}
.testimonials-section .testimonial-card__text h3 {
  font-weight: 600;
}
.faq-row-wrapper ul {
  padding-left: 20px;
}
.faq-row-wrapper ul li {
  list-style: disc;
}

/*Header css*/
.mobile-dropdown {
  position: absolute;
  right: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 100vw;
  overflow: auto;
  z-index: 1;
  top: -10px;
  padding: 30px;
  height: calc(100vh + 10px);
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.mobile-dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.web-dropdown {
  position: relative;
  display: inline-block;
}
/* .menu-products-dropbtn{
    font-size: 20px;
} */
.menu-products-dropdown,
.menu-learn-dropdown {
  display: none;
  position: absolute;
}

.menu-products-dropdown.active,
.menu-learn-dropdown.active {
  display: block;
  width: 340px;
  padding: 10px 5px;
  top: -8px;
  left: calc(100% - 170px);
  border-radius: 15px;
  background: #fff;
}
.menu-learn-dropdown.active {
  width: 220px;
  left: calc(100% - 140px);
}
.menu-learn-dropdown.active .badge {
  margin-left: 5px;
}
.menu-products-dropdown.active .nav-icon,
.menu-learn-dropdown.active .nav-icon {
  width: 48px;
  height: 48px;
  float: left;
}
.menu-products-dropdown.active .nav-title,
.menu-learn-dropdown.active .nav-title {
  display: block;
  padding-left: 60px;
}
.menu-products-dropdown.active .nav-title .badge,
.menu-learn-dropdown.active .badge,
.mob-nav-badge,
.dropdown-content li a .mob-nav-badge {
  font-size: 12px;
  background: #dff2e1;
  color: #468f50;
  padding: 4px 10px;
  border-radius: 10px;
}
.dropdown-content li a .mob-nav-badge {
  margin-left: 5px;
  font-weight: normal;
}
.menu-products-dropdown.active .nav-description {
  display: block;
  padding-left: 60px;
  font-weight: 300;
  font-size: 84%;
}
.menu-products-dropdown li,
.menu-learn-dropdown li {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e6e6e6;
}
.menu-products-dropdown li:last-child,
.menu-learn-dropdown li:last-child {
  border-bottom: none;
}

.menu-products-dropdown a,
.menu-learn-dropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.mobile-menu__trigger.dropbtn:active,
.mobile-menu__trigger.dropbtn:focus {
  border: none;
  outline: none;
}

.dropdown-content span {
  position: absolute;
  background: #f6f6f6;
  width: 40px;
  height: 40px;
  right: 14px;
  top: 14px;
  z-index: 99999;
}

.dropdown-content.show span:before,
.dropdown-content.show span:after {
  top: 25px;
  left: 10px;
}

.dropdown-content li ul {
  margin-top: 5px;
}

.dropdown-content li a {
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.dropdown-content li strong {
  margin-top: 25px;
  display: block;
}

.dropdown-content li strong:first-child {
  margin-top: 20px;
}

.modified-container {
  border-radius: 50px 50px 0 0;
  color: #ffffff;
  padding: 6rem 6rem 0;
  margin-top: 6rem;
}
.modifying-container {
  border-radius: 50px;
  color: #000;
  padding: 5rem;
  margin-block: 1.5rem;
}
.split-container {
  color: #ffffff;
  height: 680px;
  overflow: hidden;
  width: 100%;
  max-width: 1680px;
  margin: auto;
}
.split-container .video-container {
  width: 40%;
  display: inline-block;
  min-height: 680px;
  background-color: #000000;
  height: 100vh;
  overflow: hidden;
  float: left;
}
.split-container .video-container video {
  max-width: none;
  position: relative;
  width: 1208px;
  left: -50%;
}
.split-container .video-container-text {
  width: 60%;
  display: inline-block;
  min-height: 680px;
  float: left;
  padding: 8rem;
}

.black--background .modified-container {
  padding-top: 0;
  margin-top: 0;
}
.dark-green--background .modified-container {
  padding: 0;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-top: 0;
}

.content-tiles {
  width: 100%;
}

.content-tiles .tile {
  width: 25%;
  display: inline-block;
  height: 780px;
  background-size: cover;
  background-position: right;
  color: #ffffff;
  position: relative;
}

.content-tiles .tile.tile-1 {
  background-image: url('../../assets/tile-1.png');
}

.content-tiles .tile.tile-2 {
  background-image: url('../../assets/tile-2.png');
}

.content-tiles .tile.tile-3 {
  background-image: url('../../assets/tile-3.png');
}

.content-tiles .tile.tile-4 {
  background-image: url('../../assets/tile-4.png');
}

.content-tiles .tile span {
  position: absolute;
  bottom: 50px;
  left: 30px;
}

.fature-section.integration-cover {
  background-image: url('../../assets/partner-page-integration-shot.png');
  background-repeat: no-repeat;
  background-position: 101% 275px;
}
.fature-section.integration-cover.seamless-cover {
  background-image: url('../../assets/swift-signup-process.png');
  min-height: 580px;
  background-position-x: 100%;
}
.fature-section.integration-cover.seamless-cover .step {
  border-left: 3px solid #f1f3f4;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.fature-section.integration-cover.seamless-cover .step.active {
  border-left: 3px solid #ff147d;
  opacity: 1;
}
.fature-section.integration-cover.seamless-cover .step .text-xl {
  font-size: 130%;
  font-weight: 800;
}
.fature-section.integration-cover.seamless-cover .step {
  opacity: 0.3;
}
.fature-section.integration-cover.seamless-cover .step .text {
  opacity: 0.3;
}
.brand-logo {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: auto;
  text-align: center;
}

.brand-logo li {
  display: inline-block;
  margin: 30px 100px;
}

.brand-logo li img {
  object-fit: cover;
}

.seamless-integration .modified-container {
  border-radius: 40px;
  margin-top: 3rem;
  margin-bottom: 6rem;
  padding: 6rem;
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

.seamless-integration .modified-container h1 {
  font-size: 4.25rem;
}

.seamless-integration .modified-container.business-first h1 {
  color: #ffd0e5;
}
.seamless-integration .modified-container.business-second h1 {
  margin-top: 80px;
}

.seamless-integration .modified-container .order-first img {
  top: 120px;
  position: relative;
  left: 100px;
  transform: scale(1.4);
}

.seamless-mob-shot {
  background-image: url('../../assets/casava-phone-shoot-1.png');
  background-repeat: no-repeat;
  height: 560px;
  background-size: 100%;
}

.seamless-mob-shot2 {
  background-image: url('../../assets/casava-phone-shoot-2.png');
  background-repeat: no-repeat;
  height: 720px;
  background-size: 100%;
  border-radius: 40px;
}

.seamless-integration.integration2 {
  color: #000000;
}

.seamless-integration.integration2 .modified-container {
  color: #000000;
  padding: 0;
}

.round-section.fature-section .modified-container {
  border-radius: 40px;
  background: #ffffff;
  color: #000000;
  padding: 0;
  overflow: hidden;
  margin-top: 0;
}
.round-section.fature-section .modified-container .bg-image-layout {
  background-image: url('../../assets/casava-financial-img.png');
  width: 100%;
  height: 100%;
  min-height: 660px;
  background-size: cover;
}

.apply_for_access_bg {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.apply_for_access_bg .modified-container {
  position: relative;
  top: -250px;
  height: 0;
  padding-bottom: 6rem;
}

.scale-img {
  transform: scale(1.3);
}

.opacioty-half {
  opacity: 0.5;
}

/* modal styles */
.modal-cover:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
.modal {
  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  background: #f9f9f9;
  padding: 6rem 3rem 2rem;
  z-index: 9999999999;
}
.modal {
  width: 100%;
  height: 100%;
}
.modal.modal-health-insurance .bg-modal-health-insurance,
.modal.modal-health-cash .bg-modal-health-cash {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 700px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 40px;
}
.modal.modal-health-insurance .bg-modal-health-insurance {
  background-image: url('../../assets/health-hero-wide.jpg');
}
.modal.modal-health-cash .bg-modal-health-cash {
  background-image: url('../../assets/landing-health-cash.jpg');
}
.modal form,
.modal .form-pseudo {
  width: 100%;
  max-width: 470px;
  background: #ffffff;
  float: left;
  border-radius: 20px;
  text-align: center;
  padding: 25px;
}
.modal form img,
.modal .form-pseudo img {
  margin: auto;
  width: 64px;
  border-radius: 32px;
}
.modal form h3,
.modal .form-pseudo h3 {
  font-size: 24px;
  margin-top: 15px;
}
.modal form p,
.modal .form-pseudo p {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.modal .form-pseudo .button {
  width: 180px;
  margin-bottom: 50px;
}
.modal .info {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-top: 24px;
  font-size: 14px;
}
.modal .form-placeholder-icon {
  width: 18px;
  height: 18px;
  border-radius: 0;
  position: absolute;
  top: 38px;
  left: 14px;
}
.modal .form-icon-green {
  background: #d7efda;
  width: 24px;
  height: 24px;
  padding: 5px;
  float: left;
  margin-right: 5px;
  margin-top: -1px;
}
.modal .success-icon {
  background: #d7efda;
  width: 48px;
  height: 48px;
  display: block;
  margin: auto;
  border-radius: 24px;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.modal .success-icon .form-icon-green-large {
  width: 20px;
}
.modal [type='button'],
.modal [type='reset'],
.modal [type='submit'] {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 9999px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: #000;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal-close {
  width: 30px;
  position: absolute;
  right: 70px;
  top: 120px;
  background: #fff;
  border-radius: 40px;
  padding: 8px;
  cursor: pointer;
}
.modal .danger-form-validation {
  margin: 0 !important;
  position: absolute;
  top: 68px;
  font-size: 12px !important;
  left: 15px;
  color: red;
}
.modal form .button:before,
.modal form button:before {
  background: transparent;
}

.modal-perks {
  background: rgba(16, 30, 57, 0.6);
  display: block;
  width: 100%;
  height: 100vh;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
}
.modal-perks .modal {
  max-width: 600px;
  left: 50%;
  margin-left: -300px;
  background: transparent;
  padding: 0;
  top: 90px;
}
.modal-perks .modal.modal-health-cash .bg-modal-health-cash,
.modal-perks .modal.modal-health-insurance .bg-modal-health-insurance {
  background-image: none;
  background: #f2f2f7;
  border-radius: 40px;
}
.modal-perks .modal form,
.modal-perks .modal .form-pseudo {
  max-width: none;
}
.modal-perks .modal-close {
  right: -5px;
  top: -5px;
  background: #f2f2f7;
}
/* floating form fields styles */
.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 0px;
  width: 100%;
  float: left;
  position: relative;
}
.field-half {
  width: calc(50% - 10px);
  float: left;
}
.field-half.first-child {
  margin-right: 10px;
}
.field-half.last-child {
  margin-left: 10px;
}
.field label,
.field input {
  transition: all 0.2s;
  touch-action: manipulation;
}
.field input {
  font-size: 18px;
  color: rgb(128, 128, 128);
  border: 0;
  border: 1px solid rgb(230, 230, 230);
  appearance: none;
  -webkit-appearance: none;
  border-radius: 28px;
  padding: 0;
  cursor: text;
  height: 44px;
  padding-left: 42px;
}
.field input:focus {
  outline: 0;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.field label {
  letter-spacing: 0.05em;
}
.field input:placeholder-shown + label {
  cursor: text;
  text-align: left;
  width: auto;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(42px, 36px) scale(1.16);
}
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
.field input:focus::-webkit-input-placeholder {
  opacity: 1;
}
.field input + label,
.field input:not(:placeholder-shown) + label,
.field input:focus + label {
  transform: translate(15px, 0px) scale(0.9);
  cursor: pointer;
  text-align: left;
}

.card-about-cover {
  max-width: 960px;
  margin: auto;
}
.card-about-exp:before {
  content: '*';
  font-size: 50px;
  color: #ff147d;
  position: absolute;
  top: -10px;
  left: -12px;
  font-weight: bold;
}
.card-about-exp {
  position: relative;
  padding: 20px 25px;
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  box-shadow: 8px 8px #f8f8f8;
  margin-top: 20px;
}
.card-about-exp strong {
  font-size: 136%;
  line-height: 1.24;
  margin-bottom: 15px;
  display: block;
}

.card-about-share {
  position: relative;
  padding: 70px 100px;
  border-radius: 10px;
  height: 460px;
  border: 0;
  margin-top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #ff147d;
}
.card-about-share:before {
  content: '“';
  font-size: 62px;
  color: #ffffff;
  position: absolute;
  top: 20px;
  left: 40px;
  font-weight: bold;
}
.card-about-share strong {
  display: block;
  margin-bottom: 100px;
  font-size: 22px;
}
.card-about-share p {
  font-size: 18px;
}

.card-about-stats {
  border-left: 1px solid #fd0093;
  padding: 20px;
}
.card-about-stats strong {
  font-size: 86px;
  font-family: Gooper, serif;
  line-height: 86px;
}

.casava-stamp {
  position: absolute;
  top: -100px;
  right: 100px;
}
.casava-stamp img {
  max-width: 200px;
}

.text-pink {
  color: #ff147d;
}
.leaf-petals--background img {
  max-width: 500px;
}
.license-list {
  display: inline-block;
  padding: 0;
}
.license-list li {
  margin: 0;
  padding: 0;
  float: left;
  width: 54px;
  margin-right: 20px;
  margin-top: 30px;
}

/* carousel slider styles */
.swiper {
  width: 100%;
}
.swiper-pagination {
  display: block;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper .slide-1 .card-about-share {
  background-image: url('../../assets/user-image-ejiro-transparent.png');
}
.swiper .slide-2 .card-about-share {
  background-image: url('../../assets/user-image-ayodile-transparent.png');
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
/* responsive style common */

@media (max-width: 1024px) {
  h1 {
    font-size: 62px;
  }
  h2 {
    font-size: 56px;
  }
  section.hero-section {
    background-size: cover;
    min-height: 500px;
    padding: 0 1rem;
  }
  section.hero-section .md\:gap-20 {
    grid-gap: 3rem;
    gap: 3rem;
  }
  section.hero-section h1 {
    margin-top: 100px;
  }
  .value-props-section .our-products {
    display: flex;
  }
  .value-props-section .card-item {
    width: 31%;
    margin: 1%;
    padding: 30px 20px 40px;
    min-height: 430px;
  }
  .value-props-section .card-item a {
    margin-bottom: 30px;
  }
  .value-props-section .card-item p {
    font-size: 14px;
  }
  section.hero-section h1,
  .howto-section h1 {
    font-size: 62px;
  }
  section.hero-section h1 br,
  .howto-section h1 br {
    display: none;
  }
  .text-2xl {
    font-size: 20px;
  }
  .claims-demo-section .demo-card {
    width: 100%;
  }
  .howto-section img,
  .claims-demo-section img {
    max-width: 100%;
  }
  section,
  .claims-demo-section,
  .howto-section,
  .hospitals-section,
  .health-perks-section {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .hospitals-section,
  .health-perks-section {
    padding-top: 0;
  }
  .health-perks-section {
    padding-bottom: 0;
  }
  .health-steps-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lg\:mt-32 {
    margin-top: 4rem;
  }
  .faq-row .row-title-text {
    font-size: 18px;
  }
  section.faq-section {
    margin-bottom: 0;
  }
  .footer-cta__card p {
    font-size: 16px;
  }
  section .pill-item {
    margin-top: 40px;
  }
  .fature-section.integration-cover {
    background-position: 101% 293px;
    background-size: 420px;
  }
  .content-tiles .tile {
    height: 380px;
  }
  .modal {
    max-width: none;
    min-width: auto;
    /* width: calc(100% - 80px); */
    /* margin-left: 40px; */
    /* left: 0; */
  }
}

@media (max-width: 840px) {
  section.hero-section .md\:grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  nav.site-header .nav-menu {
    padding: 0 40px;
  }
  nav.site-header .hiring-cta {
    display: none;
  }
  section.hero-section h1 {
    margin-top: 100px;
  }
  .claims-ai-section {
    text-align: center;
  }
  .claims-ai-section .button {
    display: block;
    margin: auto;
    margin-top: 30px;
  }
  .fature-section.integration-cover {
    background-position: 101% 517px;
    background-size: 500px;
    height: 860px;
  }
  .about-page-wrapper .stats-counter .grid-cols-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .about-page-wrapper .stats-counter .card-about-stats strong {
    font-size: 56px;
    line-height: 56px;
  }
  .card-about-share {
    height: 320px;
    padding: 70px 50px;
  }
  .card-about-share strong {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .card-about-share p {
    font-size: 14px;
  }
  .casava-stamp {
    top: -70px;
  }
  .casava-stamp img {
    max-width: 140px;
  }
  .split-container {
    height: 520px;
  }
  .split-container .video-container {
    min-height: 520px;
  }
  .split-container .video-container video {
    height: 520px;
  }
  .split-container .video-container-text {
    padding: 4rem;
  }
  .split-container h2 {
    font-size: 32px;
  }
  .about-page-wrapper .fature-section .image-layout img {
    margin: auto;
  }
  .about-page-wrapper .text-xl {
    font-size: 18px;
  }
  .about-page-wrapper section.hero-section h1 br {
    display: block;
  }
}

@media (max-width: 768px) {
  section.hero-section {
    background-position: center;
  }
  h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 48px;
  }
  nav.site-header .nav-menu {
    padding: 0 30px;
  }
  nav.site-header .hiring-cta {
    display: none;
  }
  section.hero-section {
    min-height: 400px;
    margin: 6rem 2rem 2rem;
  }
  section,
  .claims-demo-section,
  .howto-section,
  .hospitals-section,
  .health-perks-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .value-props-section .our-products {
    margin-top: 2rem;
  }
  .value-props-section .card-item a {
    padding: 10px 15px;
  }
  .howto-section h1 br,
  .howto-section p br,
  .footer-cta__card p br {
    display: none;
  }
  section.hero-section h1,
  .howto-section h1,
  .value-props-section h1 {
    font-size: 56px;
  }
  .howto-section,
  .claims-demo-section {
    text-align: center;
  }
  .howto-section h1 {
    margin-bottom: 10px;
  }
  .howto-section img,
  .claims-demo-section img {
    max-width: 500px;
    margin: auto;
  }
  .claims-demo-section .demo-card {
    display: inline-table;
    margin-bottom: 20px;
  }
  .claims-demo-section .demo-card .pill-item {
    left: 50px;
  }
  .footer-cta__card {
    min-height: 270px;
    padding: 30px;
  }
  .footer-cta__card h2 {
    position: relative;
    z-index: 1;
  }
  .estimate-section .get-amount svg {
    right: -10px;
  }
  .income-policy-details-section .justify-end {
    display: block;
  }
  .income-policy-details-section .justify-end .md\:w-2\/4 {
    width: 100%;
  }
  .text-cta-secton,
  .income-protection-section,
  .health-perks-section {
    text-align: center;
  }
  .health-steps-section .step-item:last-child {
    margin-bottom: 0;
  }
  .health-perks-section .coverage-features .perk-item {
    max-width: 50%;
    padding: 0 10px;
  }
  .health-steps-section h2 br {
    display: none;
  }
  .fature-section {
    text-align: center;
  }
  .fature-section .button {
    margin: 2.5rem auto 0;
  }
  .modal {
    background: #ffffff;
    padding: 6rem 2rem 2rem;
  }
  .modal-close {
    right: 60px;
  }
  .modal form,
  .modal .form-pseudo {
    max-width: none;
    /* padding: 0; */
  }
  .modal form h3,
  .modal .form-pseudo h3 {
    font-size: 24px;
  }
  .card-about-exp {
    text-align: left;
  }
  .swiper .card-about-share {
    text-align: left;
  }
  .split-container {
    height: auto;
    width: 100%;
  }
  .split-container .video-container,
  .split-container .video-container-text {
    width: 100%;
    min-height: auto;
    height: auto;
  }
  .split-container .video-container video {
    width: 100%;
    height: auto;
    margin: 0;
    left: 0;
  }
  .modal-perks .modal {
    max-width: calc(100% - 30px);
    padding: 0;
    left: 0;
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .visible-small {
    display: block;
  }
  .hidden-small {
    display: none;
  }
  section .pill-item {
    display: none;
  }
  nav .mobile-menu__trigger {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  section.hero-section {
    /* margin: 25px; */
    /* margin-top: 80px; */
  }
  section.hero-section.landing-page {
    background-position: center;
  }
  section.hero-section h1 {
    margin-top: 60px;
  }
  .value-props-section .our-products {
    display: block;
  }
  .value-props-section .card-item {
    width: 100%;
    padding: 40px 20px;
    margin-bottom: 20px;
    min-height: 200px;
  }
  .value-props-section .card-item img {
    width: 50%;
  }
  .health-perks-section .coverage-features .perk-item p {
    font-size: 16px;
  }
  .dropdown-content .sub-menu-inner li a {
    padding: 10px 0;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 60px;
  }
  .icon-mob-nav {
    width: 48px;
    float: left;
    margin-right: 7px;
    position: absolute;
    left: 28px;
  }
  .dropdown-content .sub-menu-inner li a strong {
    margin-top: 0;
    display: inline-block;
  }
  section.hero-section.about-page .visible-small {
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .dropdown-content li strong:first-child {
    margin-top: 0;
  }
  .about-page-wrapper .stats-counter .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  section.hero-section h1 br {
    display: block;
  }
  section.hero-section.landing-page,
  section.hero-section.income-protection-hero,
  section.hero-section.health-hero,
  section.hero-section.health-cash-hero,
  section.hero-section.partners-page,
  section.hero-section.business-gro-page,
  section.hero-section.claims-hero,
  section.hero-section.about-page,
  section.hero-section.business-page,
  section.hero-section.credit-life-page {
    background-position: 75% 0;
    background-size: cover;
    min-height: 100vh;
  }
  section.hero-section.about-page {
    background-size: contain;
    background-position: bottom;
    background-color: #0b3e36;
    background-image: url('../../assets/about-hero-responsive.jpg');
  }
  section.hero-section.landing-page {
    background-size: cover;
    background-position: bottom;
    background-image: url('../../assets/landing-hero-responsive.jpg');
  }
  section.hero-section.income-protection-hero {
    background-position: 55% 0;
  }
  section.hero-section.health-cash-hero {
    background-position: 45% 0;
  }
  section.hero-section {
    background-size: 200% auto;
  }
  .value-props-section {
    text-align: center;
  }
  .value-props-section .card-item {
    text-align: left;
  }
  section.hero-section p br {
    display: none;
  }
  section,
  .claims-demo-section,
  .howto-section,
  .hospitals-section,
  .health-perks-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h1,
  section.hero-section h1,
  .howto-section h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 40px;
  }
  h5 {
    font-size: 22px;
  }
  .health-steps-section .step-item h5,
  .health-steps-section .step-item p {
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .howto-section img,
  .claims-demo-section img {
    max-width: 400px;
  }
  .faq-section h1 {
    margin-bottom: 40px;
  }
  .faq-row-wrapper {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  footer.site-footer {
    padding-top: 0;
  }
  .claims-demo-section .demo-card {
    position: relative;
    top: 320px;
    text-align: left;
  }
  .claims-demo-section .demo-card .pill-item {
    left: -20px;
  }
  .value-props-section .card-item {
    margin-left: 0;
  }
  .value-props-section .card-item img {
    width: 75%;
  }
  .value-props-section .card-item .mt-auto {
    position: relative;
    z-index: 0;
  }
  footer.site-footer .footer-menu h6 {
    margin-bottom: 15px;
  }
  footer.site-footer .footer-menu li,
  footer.site-footer .footer-menu p,
  footer.site-footer {
    margin-bottom: 10px;
  }
  .footer-menu .footer-social__icons {
    margin-top: 10px;
  }
  footer.site-footer .copyright-text {
    margin-top: 24px;
  }
  .income-policy-details-section .income-policy-card {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .health-perks-section .coverage-features .perk-item {
    max-width: 100%;
    margin: 40px 0 0 0;
  }
  .health-perks-section h5 {
    margin-top: 10px;
  }
  .claims-steps-section .step-item p {
    font-size: 18px;
    line-height: 22px;
  }
  .claims-steps-section .step-item .number-circle {
    margin-right: 20px;
    margin-top: 7px;
    margin-bottom: 7px;
    transform: scale(0.8);
  }
  .claims-paid-in {
    position: relative;
    top: -500px;
    text-align: left;
  }
  .footer-cta__umbrella {
    display: block;
    top: 20px;
  }
  .footer-cta__card {
    min-height: 560px;
  }
  .footer-cta__card h2 {
    margin-top: 270px;
  }
  .estimate-section .salary-input-field {
    margin-top: 30px;
  }
  .what-you-get-section {
    background-image: none;
    text-align: center;
  }
  .black--background .modified-container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .dark-green--background .modified-container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .modified-container {
    margin-top: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .fature-section.integration-cover {
    background-image: none;
    height: auto;
  }
  .content-tiles .tile {
    width: 50%;
    margin: -3px 0;
  }
  .content-tiles .tile span {
    left: 20px;
  }
  .modal {
    /* top: 40px;
        padding: 30px 15px;
        width: calc(100% - 40px);
        margin-left: 20px; */
    padding: 0;
  }
  .modal.modal-health-cash .bg-modal-health-cash {
    background: none;
  }
  .modal-close {
    right: 20px;
    top: 20px;
    border: 1px solid #000;
  }
  .modal form h3,
  .modal .form-pseudo h3 {
    font-size: 20px;
    margin-top: 10px;
  }
  .modal form p,
  .modal .form-pseudo p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .field-half {
    width: 100%;
  }
  .field-half.first-child {
    margin-right: 0;
  }
  .field-half.last-child {
    margin-left: 0;
  }
  .modal .form-pseudo .button {
    margin-bottom: 10px;
    margin-top: 40px;
    width: 140px;
  }
  .casava-stamp {
    top: -40px;
    right: 20px;
  }
  .casava-stamp img {
    max-width: 80px;
  }
  .card-about-share {
    height: 200px;
    padding: 40px 20px;
  }
  .card-about-share:before {
    top: 0px;
    left: 20px;
  }
  .card-about-share strong {
    margin-bottom: 0;
  }
  .leaf-petals--background {
    padding-bottom: 180px;
    background-size: 400%;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
  .swiper .card-about-share {
    height: 480px;
    background-position: bottom;
  }
  .stats-counter,
  .card-about-stats {
    text-align: left;
  }
  .about-page-wrapper .text-xl {
    font-size: 16px;
  }
  .split-container .video-container-text {
    text-align: left;
  }
  .hospitals-section .hospitals-carousel-container:before,
  .brands-section .hospitals-carousel-container:before {
    display: none;
  }
  .hospitals-carousel__wrapper img {
    max-width: none;
    width: 120px;
  }
  .scale-img {
    transform: scale(1);
  }
  .seamless-integration .modified-container {
    padding: 2rem;
    border-radius: 0;
    margin: 0;
  }
  .seamless-integration .modified-container h1,
  .seamless-integration .modified-container.business-first h1,
  .seamless-integration .modified-container.business-second h1 {
    font-size: 52px;
    margin-top: 40px;
  }
  .seamless-integration .modified-container .order-first img {
    top: 70px;
    left: 0;
    transform: scale(0.9);
  }
  .seamless-mob-shot {
    height: 360px;
  }
  .seamless-mob-shot2 {
    border-radius: 0;
    height: 500px;
  }
  .round-section.fature-section .modified-container {
    border-radius: 0;
  }
  .round-section.fature-section .modified-container .bg-image-layout {
    min-height: 400px;
  }
  .light-lime--background {
    background: transparent;
  }
  .credit-life-section.health-perks-section .coverage-features .perk-item {
    width: calc(100% - 30px);
    padding: 20px 15px;
    min-height: auto;
  }
  .credit-life-section.health-perks-section
    .coverage-features
    .perk-item
    .button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .claims-demo-section .demo-card {
    top: 440px;
  }
  .fature-section.integration-cover.seamless-cover {
    background-image: none;
    min-height: auto;
  }
  .apply_for_access_bg .modified-container {
    top: -170px;
  }
}

@media (max-width: 425px) {
  section.hero-section {
    background-size: 250% auto;
  }
  .value-props-section .card-item {
    padding: 15px 20px;
    min-height: 400px;
  }
  .value-props-section .card-item img {
    width: 100%;
  }
  .value-props-section .card-item .mt-auto {
    margin-top: auto;
  }
  .value-props-section .card-item .mt-auto h3 {
    margin-bottom: 5px;
  }
  .value-props-section .card-item .mt-auto p {
    margin-bottom: 15px;
  }
  .value-props-section .card-item a {
    display: inline-block;
    margin: 0;
    margin-bottom: 8px;
  }
  .howto-section .grid {
    display: block;
  }
  .howto-section img,
  .claims-demo-section img {
    max-width: 100%;
  }
  .howto-section img {
    margin-top: 20px;
  }
  .text-xl {
    font-size: 16px;
  }
  .gap-10 {
    grid-gap: 20px;
    gap: 20px;
  }
  .lg\:mt-32 {
    margin-top: 30px;
  }
  .testimonials-section .testimonial-photos .active {
    height: 95px;
  }
  .income-policy-details-section .income-policy-card {
    min-height: 0;
  }
  .faq-section h1 {
    margin-bottom: 20px;
  }
  .what-you-get-section h1 {
    margin-top: -40px;
  }
  .what-you-get-section p {
    margin-bottom: 40px;
  }
  .fature-section .image-layout {
    height: auto;
  }
  .hospitals-section
    .hospitals-carousel-container
    .hospitals-carousel__wrapper
    .carousel-item,
  .brands-section
    .hospitals-carousel-container
    .hospitals-carousel__wrapper
    .carousel-item {
    text-align: center;
  }
  .content-tiles .tile {
    height: 280px;
  }
  .seamless-integration .modified-container h1,
  .seamless-integration .modified-container.business-first h1,
  .seamless-integration .modified-container.business-second h1 {
    font-size: 36px;
  }
}

@media (max-width: 375px) {
  section.hero-section .container {
    padding-right: 0;
    padding-left: 0;
  }
  h1,
  section.hero-section h1,
  .howto-section h1,
  .value-props-section h1 {
    font-size: 36px;
  }
  .text-2xl br {
    display: none;
  }
  .testimonials-section .grid {
    gap: 20px;
  }
  .footer-cta__card h2 {
    font-size: 30px;
  }
  .footer-cta__card {
    padding: 20px;
  }
  section.hero-section {
    min-height: 340px;
  }
  section.hero-section h1 {
    margin-top: 40px;
  }
  footer.site-footer .footer-menu .footer-social__icons {
    display: block;
    margin: 0;
  }
  footer.site-footer .copyright-text {
    margin: 0;
  }
  .income-policy-details-section .income-policy-card {
    padding: 20px;
    margin-top: 20px;
  }
  .income-policy-details-section .income-policy-card .text-xl {
    margin-bottom: 10px;
  }
  .income-policy-details-section .income-policy-card .button {
    font-size: 13px;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .income-policy-details-section .income-policy-card .faq-row-wrapper .faq-row {
    margin-bottom: 10px;
  }
  .health-steps-section .step-item {
    padding: 15px;
  }
  .health-steps-section .step-item .ml-8 {
    margin-left: 15px;
  }
  .claims-ai-section .image-layout,
  .what-you-get-section .image-layout {
    height: auto;
  }
  .claims-demo-section .demo-card {
    top: 360px;
  }
  .card-about-share strong {
    font-size: 14px;
  }
  .split-container .video-container-text {
    padding: 2rem;
  }
  .license-list li {
    width: 36px;
  }
}

@media (max-width: 320px) {
  section.hero-section {
    background-size: 300% auto;
  }
  .claims-demo-section .demo-card {
    height: 350px;
  }
  .claims-paid-in {
    top: -370px;
  }
  .apply_for_access_bg .modified-container {
    top: -150px;
  }
}
.reviews--section {
  background: #f5f5f5;
}
.reviews--heading {
  font-size: 56px;
}
.reviews--card {
  background: #fff;
  padding: 40px 20px;
  border-radius: 22px;
  width: 100%;
}
.reviews--intro {
  display: grid;
  grid-template-columns: 1fr;
  gap: 26px;
  margin-bottom: 26px;
}
.reviews--rating--card {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}
.reviews--rating--score {
  font-size: 32px;
  font-weight: bold;
}
.reviews--rating--card .stars {
  display: flex;
  gap: 15px;
}
.reviews--rating--card .star {
  background: #dcdce6;
  display: grid;
  height: 50px;
  place-items: center;
  position: relative;
  isolation: isolate;
  width: 50px;
}
.reviews--rating--card .star svg {
  height: 32px;
  width: 32px;
}
.reviews--rating--card .star:before {
  background: #468f50;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.reviews--rating--card .star.half:before {
  width: 50%;
}
.reviews--rating--card .star.full:before {
  width: 100%;
}
.reviews--rating--card .review_count {
  font-size: 20px;
  line-height: 28px;
}

.reviews--slider--wrapper {
  margin-bottom: 40px;
}
.reviews--slider--track {
  /* pointer-events: none; */
  overflow-x: auto;
  overflow-y: clip;
  position: relative;
  padding-bottom: 26px;

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.reviews--slider--track::-webkit-scrollbar {
  display: none;
}
.reviews--slider--dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
}
.reviews--slider--dots .dot {
  background: #fff;
  border-radius: 999px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  width: 16px;
}
.reviews--slider--dots .dot[aria-selected='true'] {
  background: #ff147d;
}
.reviews--slider {
  align-items: flex-start;
  display: flex;
  gap: 26px;
  white-space: nowrap;
  width: 100%;
}
.reviews--comment--card {
  /* pointer-events: auto; */
  flex-shrink: 0;
  white-space: normal;
  width: 100%;
}
.reviews--comment--card .comment {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
}
.reviews--comment--card .comment--author {
  font-size: 20px;
  line-height: 1.35;
  margin-bottom: 8px;
}
.reviews--comment--card .stars {
  display: flex;
  gap: 2.67px;
  justify-content: flex-end;
}
.reviews--trust-pilot-logo svg {
  /* height: 45px; */
  /* width: 197px; */

  height: 30px;
  width: 130px;
}
@media screen and (min-width: 769px) {
  .reviews--card {
    padding: 40px;
    border-radius: 44px;
  }
  .reviews--heading {
    font-size: 56px;
  }
  .reviews--intro {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .reviews--slider .reviews--comment--card {
    width: calc(50% - 13px);
  }
}
@media screen and (min-width: 1280px) {
  .reviews--heading {
    grid-column: 1 / 3;
    font-size: 98px;
  }
  .reviews--intro {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .reviews--slider .reviews--comment--card {
    width: calc((100% / 3) - (26px / 1.5));
  }
}

@media screen and (min-width: 1920px) {
  .reviews--heading {
    grid-column: 1 / 3;
    font-size: 134px;
  }
  .reviews--rating--card .star {
    height: 75px;
    width: 75px;
  }
  .reviews--rating--card .star svg {
    height: 52.5px;
    width: 52.5px;
  }
  .reviews--comment--card .comment {
    font-size: 24px;
    line-height: 1.5;
  }
  .reviews--comment--card .comment--author {
    font-size: 27px;
  }
  .reviews--trust-pilot-logo svg {
    height: 45px;
    width: 197px;
  }
}

.reviews--show--more {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  font-size: 14px;
  opacity: 0.5;
  font-weight: semibold;
}

.form-section.make-a-claim {
  border: 1px solid #e5e5e5;
  padding: 40px 20px;
  border-radius: 22px;
  max-width: 650px;
  margin: auto;
  width: 100%;
}
.form-section.make-a-claim .avatar-img {
  background: #fff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
@media screen and (min-width: 769px) {
  .form-section.make-a-claim {
    padding: 40px;
  }
}
.form-section.make-a-claim .flex {
  gap: 20px;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  width: 100%;
}
.form-group label {
  font-size: 16px;
  font-weight: 500;
}
.form-group input,
.form-group textarea,
.form-group select {
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px 16px;
  height: 44px;
  width: 100%;
}
.form-group textarea {
  height: 120px;
}
.form-group select {
  cursor: pointer;
}
.form-section.make-a-claim .danger-form-validation {
  color: #ff147d;
  font-size: 14px;
  margin-top: 4px;
}

.empowerment-banner {
  background-color: #ff147d;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
}
.empowerment-banner a {
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .empowerment-banner {
    margin-bottom: -5rem;
    margin-top: 5rem;
  }
}
