.privacy-banner {
  background: #fff1f8;
  border-radius: 40px 40px 0px 0px;
  max-width: 1920px;
  margin-top: 102px;
  margin: 102px 30px 150px;
}

.privacy-content ol {
  list-style-type: decimal;
}
.privacy-content ul {
  list-style-type: disc;
}
.privacy-content ol, .privacy-content ul {
  padding-left: 32px;
  margin-bottom: 32px;
}
.privacy-content p, .privacy-content li {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 24px;
}
.privacy-content h6 {
  font-family: Fellix;
  font-size: 24px;
  line-height: 1.12;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .privacy-section {
    grid-template-columns: 254px 1fr;
  }
}

@media (min-width: 1920px) {
  .privacy-content p, .privacy-content li {
    font-size: 24px;
    margin-bottom: 32px;
  }
  .privacy-content h6 {
    font-size: 32px;
    margin-bottom: 48px;
  }
}

.counter-document {
  counter-reset: counter-heading counter-list-item;
}
.counter-document h6 {
  counter-increment: counter-heading;
}
.counter-document h6::before {
  content: counter(counter-heading) ". "
}
.counter-document ol, .counter-document ul {
  counter-reset: counter-list-item;
  list-style: none;
  padding-left: 16px;
}
.counter-document ol li, .counter-document ul li {
  counter-increment: counter-list-item;
}
.counter-document ol li::before, .counter-document ul li::before {
  content: counter(counter-heading) "." counter(counter-list-item) " ";
}