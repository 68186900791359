/*! base style */
.container{
    width:100%;
    margin-right:auto;
    margin-left:auto;
    padding-right:1.5rem;
    padding-left:1.5rem
}
@media (min-width:600px){
    .container{
        max-width:600px
    }
}
@media (min-width:728px){
    .container{
        max-width:728px
    }
}
@media (min-width:984px){
    .container{
        max-width:984px
    }
}
@media (min-width:1240px){
    .container{
        max-width:1240px
    }
}
@media (min-width:1300px){
    .container{
        max-width:1300px
    }
}
.bg-pink-200{
    --bg-opacity:1;
    background-color:#fff1f8;
}
.bg-pink-400{
    --bg-opacity:1;
    background-color:#ffd0e5;
}
.bg-pink-700 {
    --bg-opacity:1;
    background-color: #ff147d;
}
.bg-green-200{
    --bg-opacity:1;
    background-color:#B2FFDF;
}
.bg-green-400{
    --bg-opacity:1;
    background-color:#8ee4c0;
}
.bg-light-green-400{
    background-color: #F7F3EF;
}
.bg-light-green-alt-400{
    background-color: #E9FFEC;
}
.block{
    display:block
}
.flex{
    display:flex
}
.inline-block {
    display: inline-block;
}
.table{
    display:table
}
.grid{
    display:grid
}
.hidden{
    display:none
}
.flex-col{
    flex-direction:column
}
.flex-grow {
    flex-grow: 1;
}
.flex-grow-0 {
    flex-grow: 0;
}
.flex-shrink {
    flex-shrink: 1;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.flex-wrap{
    flex-wrap:wrap
}
.items-center{
    align-items:center
}
.self-start{
    align-self:flex-start
}
.justify-items-center{
    justify-items:center
}
.justify-end{
    justify-content:flex-end
}
.justify-center{
    justify-content:center
}
.justify-between{
    justify-content:space-between
}
.justify-evenly{
    justify-content:space-evenly
}
.order-first{
    order:-9999
}
.order-last{
    order:9999
}
.font-sans{
    font-family:Fellix,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif
}
.font-normal{
    font-weight:400
}
.font-medium{
    font-weight:500
}
.font-semibold {
    font-weight: 600;
}
.h-full{
    height:100%
}
.text-xs{
    font-size:.75rem
}
.text-lg{
    font-size:1.125rem
}
.text-xl{
    font-size:1.25rem
}
.text-2xl{
    font-size:1.5rem
}
.my-4{
    margin-top:1rem;
    margin-bottom:1rem
}
.mt-0{
    margin-top:0
}
.mr-0{
    margin-right:0
}
.mt-4{
    margin-top:1rem
}
.mb-4{
    margin-bottom:1rem
}
.mt-5{
    margin-top:1.25rem
}
.mt-6{
    margin-top:1.5rem
}
.mb-6{
    margin-bottom:1.5rem
}
.mt-8{
    margin-top:2rem
}
.mb-8{
    margin-bottom:2rem
}
.ml-8{
    margin-left:2rem
}
.mt-10{
    margin-top:2.5rem
}
.mb-10{
    margin-bottom:2.5rem
}
.mt-12{
    margin-top:3rem
}
.mt-16{
    margin-top:4rem
}
.mb-16{
    margin-bottom:4rem
}
.mt-24{
    margin-top:6rem
}
.mt-auto{
    margin-top:auto
}
.mr-auto{
    margin-right:auto
}
.ml-auto{
    margin-left:auto
}
.opacity-50{
    opacity:.5
}
.opacity-75{
    opacity:.75
}
.py-12{
    padding-top:3rem;
    padding-bottom:3rem
}
.px-12{
    padding-left:3rem;
    padding-right:3rem
}
.sticky{
    position:-webkit-sticky;
    position:sticky
}
.text-right {
    text-align: right;
}
.text-left {
    text-align:left
}
.text-center{
    text-align:center
}
.text-pink-DEFAULT{
    --text-opacity:1;
    color:#ff147d;
    color:rgba(255,20,125,var(--text-opacity))
}
.text-white-DEFAULT{
    --text-opacity:1;
    color:#fff;
    color:rgba(255,255,255,var(--text-opacity))
}
.w-full{
    width:100%
}
.gap-10{
    grid-gap:2.5rem;
    gap:2.5rem
}
.gap-12{
    grid-gap:3rem;
    gap:3rem
}
.gap-16{
    grid-gap:4rem;
    gap:4rem
}
.grid-cols-1{
    grid-template-columns:repeat(1,minmax(0,1fr))
}
@-webkit-keyframes spin{
    to{
        transform:rotate(1turn)
    }
}
@keyframes spin{
    to{
        transform:rotate(1turn)
    }
}
@-webkit-keyframes ping{
    75%,to{
        transform:scale(2);
        opacity:0
    }
}
@keyframes ping{
    75%,to{
        transform:scale(2);
        opacity:0
    }
}
@-webkit-keyframes pulse{
    50%{
        opacity:.5
    }
}
@keyframes pulse{
    50%{
        opacity:.5
    }
}
@-webkit-keyframes bounce{
    0%,to{
        transform:translateY(-25%);
        -webkit-animation-timing-function:cubic-bezier(.8,0,1,1);
        animation-timing-function:cubic-bezier(.8,0,1,1)
    }
    50%{
        transform:none;
        -webkit-animation-timing-function:cubic-bezier(0,0,.2,1);
        animation-timing-function:cubic-bezier(0,0,.2,1)
    }
}
@keyframes bounce{
    0%,to{
        transform:translateY(-25%);
        -webkit-animation-timing-function:cubic-bezier(.8,0,1,1);
        animation-timing-function:cubic-bezier(.8,0,1,1)
    }
    50%{
        transform:none;
        -webkit-animation-timing-function:cubic-bezier(0,0,.2,1);
        animation-timing-function:cubic-bezier(0,0,.2,1)
    }
}
@media (min-width:640px){
    .sm\:container{
        width:100%;
        margin-right:auto;
        margin-left:auto;
        padding-right:1.5rem;
        padding-left:1.5rem
    }
}
@media (min-width:640px) and (min-width:600px){
    .sm\:container{
        max-width:600px
    }
}
@media (min-width:640px) and (min-width:728px){
    .sm\:container{
        max-width:728px
    }
}
@media (min-width:640px) and (min-width:984px){
    .sm\:container{
        max-width:984px
    }
}
@media (min-width:640px) and (min-width:1240px){
    .sm\:container{
        max-width:1240px
    }
}
@media (min-width:640px) and (min-width:1300px){
    .sm\:container{
        max-width:1300px
    }
}
@media (min-width:768px){
    .md\:container{
        width:100%;
        margin-right:auto;
        margin-left:auto;
        padding-right:1.5rem;
        padding-left:1.5rem
    }
}
@media (min-width:768px) and (min-width:600px){
    .md\:container{
        max-width:600px
    }
}
@media (min-width:768px) and (min-width:728px){
    .md\:container{
        max-width:728px
    }
}
@media (min-width:768px) and (min-width:984px){
    .md\:container{
        max-width:984px
    }
}
@media (min-width:768px) and (min-width:1240px){
    .md\:container{
        max-width:1240px
    }
}
@media (min-width:768px) and (min-width:1300px){
    .md\:container{
        max-width:1300px
    }
}
@media (min-width:768px){
    .md\:bg-pink-200{
        --bg-opacity:1;
        background-color:#fff1f8;
        background-color:rgba(255,241,248,var(--bg-opacity))
    }
    .md\:bg-pink-300{
        --bg-opacity:1;
        background-color:#ffe8f2;
        background-color:rgba(255,232,242,var(--bg-opacity))
    }
    .md\:block{
        display:block
    }
    .md\:flex{
        display:flex
    }
    .md\:text-xl{
        font-size:1.25rem
    }
    .md\:text-4xl{
        font-size:2.25rem
    }
    .md\:mr-10{
        margin-right:2.5rem
    }
    .md\:mr-24{
        margin-right:6rem
    }
    .md\:py-16{
        padding-top:4rem;
        padding-bottom:4rem
    }
    .md\:px-20{
        padding-left:5rem;
        padding-right:5rem
    }
    .md\:w-auto{
        width:auto
    }
    .md\:w-1\/4{
        width:25%
    }
    .md\:w-2\/4{
        width:50%
    }
    .md\:w-3\/4{
        width:75%
    }
    .md\:gap-12{
        grid-gap:3rem;
        gap:3rem
    }
    .md\:gap-20{
        grid-gap:5rem;
        gap:5rem
    }
    .md\:grid-cols-2{
        grid-template-columns:repeat(2,minmax(0,1fr))
    }
}
@media (min-width:1024px){
    .lg\:container{
        width:100%;
        margin-right:auto;
        margin-left:auto;
        padding-right:1.5rem;
        padding-left:1.5rem
    }
}
@media (min-width:1024px) and (min-width:600px){
    .lg\:container{
        max-width:600px
    }
}
@media (min-width:1024px) and (min-width:728px){
    .lg\:container{
        max-width:728px
    }
}
@media (min-width:1024px) and (min-width:984px){
    .lg\:container{
        max-width:984px
    }
}
@media (min-width:1024px) and (min-width:1240px){
    .lg\:container{
        max-width:1240px
    }
}
@media (min-width:1024px) and (min-width:1300px){
    .lg\:container{
        max-width:1300px
    }
}
@media (min-width:1024px){
    .lg\:block{
        display:block
    }
    .lg\:flex-row{
        flex-direction:row
    }
    .lg\:order-first{
        order:-9999
    }
    .lg\:order-last{
        order:9999
    }
    .lg\:mt-0{
        margin-top:0
    }
    .lg\:mt-12{
        margin-top:3rem
    }
    .lg\:mr-12{
        margin-right:3rem
    }
    .lg\:mt-32{
        margin-top:8rem
    }
    .lg\:ml-auto{
        margin-left:auto
    }
    .lg\:text-center{
        text-align:center
    }
    .lg\:w-1\/3{
        width:33.333333%
    }
    .lg\:w-2\/3{
        width:66.666667%
    }
    .lg\:w-3\/5{
        width:60%
    }
    .lg\:gap-20{
        grid-gap:5rem;
        gap:5rem
    }
    .lg\:gap-24{
        grid-gap:6rem;
        gap:6rem
    }
    .lg\:grid-cols-2{
        grid-template-columns:repeat(2,minmax(0,1fr))
    }
    .lg\:grid-cols-3{
        grid-template-columns:repeat(3,minmax(0,1fr))
    }
}
@media (min-width:1280px){
    .xl\:container{
        width:100%;
        margin-right:auto;
        margin-left:auto;
        padding-right:1.5rem;
        padding-left:1.5rem
    }
}
@media (min-width:1280px) and (min-width:600px){
    .xl\:container{
        max-width:600px
    }
}
@media (min-width:1280px) and (min-width:728px){
    .xl\:container{
        max-width:728px
    }
}
@media (min-width:1280px) and (min-width:984px){
    .xl\:container{
        max-width:984px
    }
}
@media (min-width:1280px) and (min-width:1240px){
    .xl\:container{
        max-width:1240px
    }
}
@media (min-width:1280px) and (min-width:1300px){
    .xl\:container{
        max-width:1300px
    }
}
@media (min-width:1280px){
    .xl\:block{
        display:block
    }
    .xl\:hidden{
        display:none
    }
    .xl\:text-left{
        text-align:left
    }
    .xl\:grid-cols-2{
        grid-template-columns:repeat(2,minmax(0,1fr))
    }
}


/* fontface style */
@font-face{
    font-family:Gooper;
    src:url(../fonts/gooper-sc-semibold.woff2) format("woff2");
    font-weight:600;
    font-display:fallback;
    font-style:normal
}
@font-face{
    font-family:Gooper;
    src:url(../fonts/gooper-sc-regular.woff2) format("woff2");
    font-weight:regular;
    font-display:fallback;
    font-style:normal
}
@font-face{
    font-family:Fellix;
    src:url(../fonts/fellix-regular.woff2) format("woff2");
    font-weight:regular;
    font-display:fallback;
    font-style:normal
}
@font-face{
    font-family:Fellix;
    src:url(../fonts/fellix-medium.woff2) format("woff2");
    font-weight:500;
    font-display:fallback;
    font-style:normal
}
@font-face{
    font-family:Fellix;
    src:url(../fonts/fellix-semibold.woff2) format("woff2");
    font-weight:600;
    font-display:fallback;
    font-style:normal
}
@font-face{
    font-family:Fellix;
    src:url(../fonts/fellix-bold.woff2) format("woff2");
    font-weight:700;
    font-display:fallback;
    font-style:normal
}

.underline {
    text-decoration: underline;
}
